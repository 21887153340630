<template>
  <div>
    <wrapper-preview-category customClass="section-preview_lk">
      <grid-table
        :data-rows="regions"
        :headers="tableHeaders.regionsMassive"
        :headers-for-rows="tableHeaders.regionsMassive"
        :loading-data="tableData.loading"
        :num-cols="tableHeaders.countCells"
        max-table-height="700px"
      >
        <template #regionName="{ row }">
          <div>{{ row.regionName }}</div>
        </template>
        <template #founderStatusRegion="{ row }">
          <div>{{ roivStatus(row) }}</div>
        </template>
        <template #actions="{ row }">
          <button
            v-if="row.founderStatus.founderWorkflow === 'SENT' || row.founderStatus.founderWorkflow === 'BLOCKED'" class="button"
            style="margin: 0; padding: 2px 5px" @click="revokeByRegionId(row.regionId)">
            Разблокировать
          </button>
        </template>
      </grid-table>
    </wrapper-preview-category>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import request from "@/services/request";
import WrapperPreviewCategory from "@/components/GridTable/dataFolder/WrapperPreviewCategory";
import AdminQuotaTableHeaders from "@/components/GridTable/dataFolder/AdminQuotaTableHeaders";
import GridTable from "@/components/GridTable/GridTable";

export default {
  name: "AdminQuotaRegions",
  components: {GridTable, WrapperPreviewCategory},
  data: () => ({
    tableHeaders: [],
    val: 0,
    dialog: false,
    tableData: {
      loading: false,
      change: {value: false, id: null},
      error: null,
      editRow: null,
      data: [],
    },
    regions: [],
  }),
  computed: {
    ...mapGetters('keycloak', {
      getAuthData: 'getAuthData',
      getUser: 'getUser',
      getUserRoles: 'getUserRoles'
    }),
  },
  
  created() {
    this.getAllForMon();
    this.tableHeaders = AdminQuotaTableHeaders(this);
  },
  methods: {
    async getAllForMon() {
      try {
        const res = await request({
          endpoint: `/api/spr/region/getAllForMon`,
          method: 'get',
          token: `${this.getAuthData.token}`
        });
        if (res.status === 200 && res.data) {
          this.regions = res.data;
          console.log('getAllForMon успех');
        }
      } catch (e) {
        console.log(e);
      }
    },
    
    async revokeByRegionId(arg) {
      try {
        const res = await request({
          endpoint: `/api/moduledatagather/revokeRegion?regionId=${arg}`,
          method: 'get',
          token: `${this.getAuthData.token}`,
        });
        if (res.status === 200 && res.data) {
          console.log('revokeByRegionId успех');
          await this.getAllForMon();
          this.dialog = false;
        }
      } catch (e) {
        console.log(e);
      }
    },
    
    roivStatus(roiv) {
      if (roiv.founderStatus.founderWorkflow === 'WORKS') {
        return 'Работает'
      } else if (roiv.founderStatus.founderWorkflow === 'SENT') {
        return 'Отправлен на согласование'
      } else if (roiv.founderStatus.founderWorkflow === 'BLOCKED') {
        return 'Заблокирован'
      } else if (roiv.founderStatus.founderWorkflow === 'ACCEPTED_MON') {
        return 'Согласовано МОНом'
      } else if (roiv.founderStatus.founderWorkflow === 'ACCEPTED_GOV') {
        return 'Согласовано Правительством'
      }
    }
  }
}
</script>

<style lang="sass">
.yellow-background
  background-color: #fff3cd
  color: #D4990A

.section-preview__header
  font-size: 24px
  font-weight: bold
  margin: 16px 0
  color: #334D6E

.dialog-btn
  .v-btn__content
    padding: 10px 5px

.v-autocomplete
  padding: 0 24px

.v-text-field
  padding: 0 24px
</style>